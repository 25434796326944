import { memo, ReactElement, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { CONFIG_VARS } from "../helper/env";
import { on } from "../helper/events";
import { Footer } from "./elements/Footer";
import { Header } from "./elements/Header";
import { LoadingOverlay } from "./elements/LoadingOverlay";
import Slide from "./elements/Slide";

interface SectionProps {
  pinned?: boolean;

  intro?: ReactElement;
  videoId: number;

  title: string;
  text: string;

  cta?: ReactElement;
}

const Page: React.FC<{ sections: Array<SectionProps> }> = (props) => {
  const [isLoading, setIsLoading] = useState(true),
    [firstRender] = useState(Date.now()),
    { sections } = props;

  // fade out the loading animation when the video is ready
  useEffect(
    () =>
      on("video:ready", (evt: any) => {
        if (evt.detail.isReady && evt.detail.src === sections[0].videoId) {
          setTimeout(
            () => setIsLoading(false),
            // minimum minLoadingScreenMS -> otherwise on the app the loading screen may flicker
            Math.max(
              firstRender + CONFIG_VARS.minLoadingScreenMS - Date.now(),
              0
            )
          );
        }
      }),
    [sections, firstRender]
  );

  // delayed scroll to top
  useEffect(() => {
    if (!isLoading) {
      requestAnimationFrame(() => {
        window.scrollTo(0, 0);
      });
    }
  }, [isLoading]);

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content={`${sections[0].title} - ${sections[0].text}`}
        />
      </Helmet>

      <LoadingOverlay isLoading={isLoading} />

      <div className="page">
        <Header />

        {sections.map((section: SectionProps) => (
          <Slide
            key={section.title}
            pinned={section.pinned}
            videoId={section.videoId}
            headline={section.title}
            text={section.text}
            intro={section.intro}
            ctaElement={section.cta}
          ></Slide>
        ))}

        <Footer />
      </div>
    </div>
  );
};

export default memo(Page);
