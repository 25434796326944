import React from "react";

import Page from "../components/Page";

const sections = [
  {
    pinned: true,
    videoId: 4001,
    title: "LOVE SHOPPING? YOU’LL LOVE THIS.",
    text: "From everyday essentials to (totally justified) splurges, Capital One helps you earn instant rewards on your purchases and find instant savings when you shop—making every shopping moment that much sweeter."
  },
  {
    videoId: 4002,
    title: "Capital One Shopping",
    text: "Save instantly with Capital One Shopping. One click is all it takes to find you the best available coupons, deals and rewards when you shop online.",
    intro: <div className="icon icon-shopping"></div>,
    cta: (
      <a className="grv-button" href="https://capitaloneshopping.com/">
        Start Saving - It’s Free
      </a>
    )
  },
  {
    videoId: 4003,
    title: "Quicksilver Card",
    text: "Enjoy cash back made simple. With the Quicksilver card, earn unlimited 1.5% cash back on every purchase, every day, no matter where you shop—it’s that easy.",
    intro: <div className="icon icon-card"></div>,
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/credit-cards/quicksilver/"
      >
        Explore Quicksilver
      </a>
    )
  },
  {
    videoId: 4004,
    title: "Shop with Rewards",
    text: "Pay for millions of items using your rewards. Apply your Capital One rewards to eligible purchases on PayPal and Amazon.com when you check out. Credit cards offered by Capital One Bank (USA), N.A. and Capital One, N.A.",
    intro: <div className="icon icon-shop-rewards"></div>,
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/credit-cards/benefits/"
      >
        Explore Rewards
      </a>
    )
  }
];

const Shopping: React.FC = () => {
  return <Page sections={sections} />;
};

export default Shopping;
