import { EffectCallback, useEffect, useState } from "react";

/**
 * The timeout is reset as soon as the effect function or the resetTimeout
 * parameter are changed.
 * @param effect The function to call when the timeout is elapsed
 * @param timeout Timeout in ms
 * @param resetTimeout A dependency that should restart the timer
 * @returns
 */
export const useTimeout = (
  effect: EffectCallback,
  timeout = 60000,
  resetTimeout?: any
) => {
  const [timeoutId, setTimeoutId] = useState(-1);

  useEffect(() => {
    const timeoutIdCur = window.setTimeout(effect, timeout);
    setTimeoutId(timeoutIdCur);

    return () => {
      clearTimeout(timeoutIdCur);
    };
  }, [setTimeoutId, effect, timeout, resetTimeout]);

  return {
    timeoutId
  };
};
