import React from "react";

import Page from "../components/Page";

const sections = [
  {
    pinned: true,
    videoId: 2001,
    title: "BUILD GOOD MONEY HABITS EARLY",
    text: "Give your family every advantage. Choose Capital One tools and resources designed to set your kids up for success. Plus, find fun videos and interesting articles to help you manage money and more."
  },
  {
    videoId: 2002,
    title: "Kids Savings",
    text: "Make saving fun with the fee-free account that lets them set goals, earn interest and has no minimum balance.",
    intro: <div className="icon icon-kids-savings"></div>,
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/bank/savings-accounts/kids-savings-account/"
      >
        Get Started
      </a>
    )
  },
  {
    videoId: 2003,
    title: "MONEY Teen Checking",
    text: "It’s the best of both worlds. Your kids manage their own money, while you keep track of their spending. There are no monthly fees or minimums, and they earn interest on any balance.",
    intro: <div className="icon icon-teen-checking"></div>,
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/bank/checking-accounts/teen-checking-account/"
      >
        Open an Account
      </a>
    )
  },
  {
    videoId: 2004,
    title: "Learn & Grow",
    text: "Get advice, hacks and inspiration on shopping, finances and everything in between.",
    intro: <div className="icon icon-learn-and-grow"></div>,
    cta: (
      <a className="grv-button" href="https://www.capitalone.com/learn-grow/">
        Find out more
      </a>
    )
  }
];

const Family: React.FC = () => {
  return <Page sections={sections} />;
};

export default Family;
