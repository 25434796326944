import { isPlatform } from "@ionic/core/dist/collection/utils/platform";

import { useMemo } from "react";

const simulateNativeInWeb = false;

export const useIsApp = (): boolean => {
  return useMemo(() => {
    if (simulateNativeInWeb && process.env.NODE_ENV !== "production") {
      return true;
    } else {
      return isPlatform("capacitor");
    }
  }, []);
};

export const useAnalyticsDomain = (): string | undefined => {
  const isApp = useIsApp();

  return useMemo(() => {
    if (isApp) {
      return undefined;
    } else {
      // const url = new URL(location.pathname);

      if (process.env.NODE_ENV === "production") {
        return "capitalonegoals.com";
      } else {
        return "testing.capitalonegoals.com";
      }
    }
  }, [isApp]);
};

export const CONFIG_VARS = {
  // minimum timeframe for loading animation
  minLoadingScreenMS: 400
};

const env = {
  useIsApp
};

export default env;
