import { memo, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import { App as NativeApp } from "@capacitor/app";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import gsap scrolltrigger for the page
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
// analytics
import Plausible from "plausible-tracker";

import ScreenSaver from "./components/Screensaver";
import { useAnalyticsDomain, useIsApp } from "./helper/env";
import { AppRoutes } from "./pages/AppRoutes";

import "./App.scss";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.config({ limitCallbacks: true });

export const appActiveSlice = createSlice({
  // A name, used in action types
  name: "appActive",
  // The initial state for the reducer
  initialState: {
    isActive: true
  },
  // An additional object of "case reducers", where the keys should be other
  // action types, or a "builder callback" function used to add more reducers
  reducers: {
    setActive: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isActive: action.payload
    })
  }
});

const App: React.FC = () => {
  const isApp = useIsApp(),
    analyticsDomain = useAnalyticsDomain(),
    disp = useDispatch();

  useEffect(() => {
    if (!isApp) {
      const { enableAutoPageviews, enableAutoOutboundTracking } = Plausible({
        trackLocalhost: true,
        domain: analyticsDomain
      });

      const cleanupPageviews = enableAutoPageviews(),
        cleanupOutbound = enableAutoOutboundTracking();

      return () => {
        cleanupPageviews();
        cleanupOutbound();
      };
    }
  }, [isApp, analyticsDomain]);

  // reactivate playing in app in case it was paused (due to inactivity)
  useEffect(() => {
    const stateListener = ({ isActive }: { isActive: boolean }) => {
      disp(appActiveSlice.actions.setActive(isActive));
    };

    const listenerResolver = NativeApp.addListener(
      "appStateChange",
      stateListener
    );

    return () => {
      listenerResolver.then((resolver) => resolver.remove());
    };
  }, [disp]);

  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="-ab1cQGnswMlZBwf5yrzOZRoCO1WToFuiBRYeOXhUZI"
        />
      </Helmet>
      <AppRoutes>{isApp && <ScreenSaver activeAfterSeconds={300} />}</AppRoutes>
    </>
  );
};

export default memo(App);
