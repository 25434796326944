import { useCallback, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { useTimeout } from "../../helper/timeout";

import "./LoadingOverlay.scss";

type LoadingPropsType = {
  isLoading: boolean;
};

export const LoadingOverlay = (props: LoadingPropsType) => {
  const [showLoading, setShowLoading] = useState(props.isLoading);

  const hideLoading = useCallback(() => {
    setShowLoading(false);
  }, [setShowLoading]);

  useTimeout(hideLoading, 10000);

  useEffect(() => {
    setShowLoading(props.isLoading);
  }, [setShowLoading, props.isLoading]);

  return (
    <CSSTransition in={showLoading} timeout={500} classNames="fade-out">
      <div className="pageLoadOverlay" aria-label="Loading">
        <div>
          <img
            className="logo"
            src="assets/img/capitalone_black.svg"
            alt="Capital One"
          ></img>
        </div>
        <div className="loader">
          <span>L</span>
          <span>O</span>
          <span>A</span>
          <span>D</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
        </div>
      </div>
    </CSSTransition>
  );
};
