import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { useIsApp } from "../../helper/env";

import "./Header.scss";

type HeaderProps = {};

export const Header: FC<HeaderProps> = (props: HeaderProps) => {
  // local props
  const isApp = useIsApp(),
    [showMenu, setShowMenu] = useState(false),
    thisElement = useRef<HTMLDivElement>(null);

  // stop propagation but show the menu if currently hidden
  const stopPropagationFunc = useCallback(
    (evt: MouseEvent) => {
      evt.stopPropagation();
      setShowMenu(!showMenu);
    },
    [showMenu, setShowMenu]
  );

  // hide the menu on actions from the doc
  const hideMenuFunc = useCallback(() => {
    setShowMenu(false);
  }, [setShowMenu]);

  useEffect(() => {
    const currentElement = thisElement.current;

    if (currentElement) {
      currentElement.addEventListener("click", stopPropagationFunc);
      document.body.addEventListener("click", hideMenuFunc);
      document.addEventListener("scroll", hideMenuFunc);
    }

    return () => {
      currentElement?.removeEventListener("click", stopPropagationFunc);
      document.body.removeEventListener("click", hideMenuFunc);
      document.removeEventListener("scroll", hideMenuFunc);
    };
  }, [isApp, thisElement, stopPropagationFunc, hideMenuFunc]);

  return (
    <>
      <div className={"headerBar " + (isApp ? "app" : "web")}>
        {isApp ? (
          <Link to="/home" aria-label="Home">
            <div className="home"></div>
          </Link>
        ) : (
          <div className="menu_container">
            <div
              className={showMenu ? "menu active" : "menu"}
              ref={thisElement}
              aria-label="menu"
              role="button"
              tabIndex={0}
              aria-haspopup="menu"
            >
              <div className="menu_line"></div>
              <div className="menu_line"></div>
              <div className="menu_line"></div>
            </div>
            <CSSTransition in={showMenu} timeout={500} classNames="fade">
              <ul className={"list_menu"}>
                <li>
                  <NavLink to="dining" activeClassName="current">
                    Do what you love and get rewarded.
                  </NavLink>
                </li>
                <li>
                  <NavLink to="travel" activeClassName="current">
                    Travel with flexibility.
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/shopping" activeClassName="current">
                    Love shopping? You’ll love this.
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/family" activeClassName="current">
                    Build good money habits early.
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/control" activeClassName="current">
                    Balance your life and your finances.
                  </NavLink>
                </li>
              </ul>
            </CSSTransition>
          </div>
        )}
        <img
          className="c1logo"
          src="assets/img/capitalone_logo_redwhite.svg"
          alt="Capital One"
        />
      </div>
    </>
  );
};
