import { useCallback } from "react";
import { useHistory } from "react-router";

export const useNavToHome = () => {
  const history = useHistory();

  return useCallback(() => {
    // only navigate if we are not on the home screen
    if (!history.location.pathname.endsWith("home")) {
      history.push("home");
    }
  }, [history]);
};

export const useNavTo = () => {
  const history = useHistory();

  return useCallback((path: string) => history.push(path), [history]);
};

export const useForceReload = () => {
  return useCallback(() => window.location.reload(), []);
};
