import React from "react";

import Page from "../components/Page";

const sections = [
  {
    pinned: true,
    videoId: 5001,
    title: "BALANCE YOUR LIFE AND YOUR FINANCES.",
    text: "With a Capital One account, you have access to tools and resources that make banking and, more importantly, life easier."
  },
  {
    videoId: 5002,
    title: "360 Checking",
    text: "Bank online anytime, almost anywhere. There’s no monthly fees or minimums. Plus, grab cash at over 70,000+ fee-free ATMs.",
    intro: <div className="icon icon-control-checking"></div>,
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/bank/checking-accounts/online-checking-account/"
      >
        Find out more
      </a>
    )
  },
  {
    videoId: 5003,
    title: "Early Direct Deposit",
    text: "Get your paycheck up to 2 days sooner than your regular payday with early direct deposit.",
    intro: <div className="icon icon-control-early-deposit"></div>,
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/bank/early-direct-deposit/"
      >
        See how
      </a>
    )
  },
  {
    videoId: 5004,
    title: "Meet Eno",
    text: "Your Capital One assistant that can notify you of changes to your credit card account. Eno can spot duplicate charges, notify you of free trials, track spending and manage your Capital One accounts.",
    intro: <div className="icon icon-control-meet-eno"></div>,
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/applications/eno/"
      >
        Get Started
      </a>
    )
  }
];

const Organization: React.FC = () => {
  return <Page sections={sections} />;
};

export default Organization;
