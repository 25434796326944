import React from "react";

import Page from "../components/Page";

const sections = [
  {
    pinned: true,
    videoId: 1001,
    title: "TRAVEL WITH FLEXIBILITY",
    text: "Wherever you are going and however you like to get there, Capital One has got you covered with benefits that flex to meet your travel needs and rewards that elevate your journey - so every trip is filled with more relaxation and less stress."
  },
  {
    videoId: 1002,
    title: "VENTURE CARD",
    text: "Earn miles you can actually use! With the venture card, earn 2x unlimited miles on every purchase then redeem on travel - from vacation rentals to car rentals and more",
    intro: <div className="icon icon-card"></div>,
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/credit-cards/venture/"
      >
        Explore Venture
      </a>
    )
  },
  {
    videoId: 1003,
    title: "Global Entry or TSA precheck Credit",
    text: "Start your trip off right with less waiting in line. Receive up to $100 credit for Global Entry or TSA Precheck when you use your Venture card",
    intro: <div className="icon icon-tsa-precheck"></div>,
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/credit-cards/benefits/"
      >
        Learn more
      </a>
    )
  },
  {
    videoId: 1004,
    title: "TRAVEL REWARDS REDEMPTION",
    text: "Redeem your rewards with flexibility. Use your miles on vacation rentals, car rentals, flights and more. You can even transfer miles to any of 15+ travel loyalty partners.",
    intro: <div className="icon icon-travel-rewards"></div>,
    cta: (
      <a className="grv-button" href="https://www.capitalonetravel.com">
        Explore Rewards
      </a>
    )
  }
];

const Travel: React.FC = () => {
  return <Page sections={sections} />;
};

export default Travel;
