import { memo, useCallback, useEffect, useRef, useState } from "react";

import { LoadingOverlay } from "../components/elements/LoadingOverlay";
import { AppVideoPlayer } from "../components/video/AppVideoPlayer";
import { CONFIG_VARS } from "../helper/env";
import { on } from "../helper/events";
import { useNavTo } from "../helper/nav";

import "./Home.scss";

const Home: React.FC = () => {
  // useEffect(
  //   () => {
  //     // starting config 1.4 secs for first video canplay event
  //     // 2 secs for last video canplay event
  //     // console.log("start loading videos: ", Date.now());

  //     // new config 1.2 / 1.5 secs for first canplay event all at the same time
  //     // ffmpeg -i input.mp4 -vcodec libx264 -profile:v main -level 3.1 -preset medium -crf 23 -x264-params ref=4 -acodec copy -movflags +faststart output.mp4

  //     // new config with scale 1.2 / 1.4
  //     // ffmpeg -i input.mp4 -v:f "scale=960:540" -vcodec libx264 -profile:v main -level 3.1 -preset medium -crf 23 -x264-params ref=4 -acodec copy -movflags +faststart output.mp4

  //   }
  // );

  const navTo = useNavTo();

  const [isLoading, setIsLoading] = useState(true);
  const [firstRender] = useState(Date.now());

  const videoCountRef = useRef<number>(1);

  // count the ready videos and than fade out loading
  const increment = useCallback(() => {
    if (isLoading) {
      if (videoCountRef.current < 5) {
        videoCountRef.current = videoCountRef.current + 1;
      } else if (videoCountRef.current === 5) {
        setTimeout(
          () => setIsLoading(false),
          // minimum 200ms loading screen
          Math.max(firstRender + CONFIG_VARS.minLoadingScreenMS - Date.now(), 0)
        );
      }
    }
  }, [isLoading, setIsLoading, firstRender]);

  // wait for homescreen videos to be playing
  useEffect(
    () =>
      on("video:ready", (evt: any) => {
        if (evt.detail.isReady) {
          increment();
        }
      }),
    [increment, firstRender]
  );

  return (
    <div>
      <LoadingOverlay isLoading={isLoading} />
      <div>
        <div className="aspect-ratio-box">
          <div className="aspect-ratio-box-inside">
            <div className="intro">
              <div
                style={{
                  left: "7.2%",
                  top: "10%",
                  width: "24.7%",
                  height: "33%",
                  zIndex: 2
                }}
              >
                <AppVideoPlayer videoId={1001} />
                <div>Travel with Flexibility</div>
              </div>

              <div
                style={{
                  left: "8.9%",
                  top: "55.8%",
                  width: "24.7%",
                  height: "33%"
                }}
              >
                <AppVideoPlayer videoId={2001} />
                <div>Build good Money Habits early</div>
              </div>

              <div
                style={{
                  left: "50.1%",
                  top: "-1.5%",
                  width: "24.7%",
                  height: "33%"
                }}
              >
                <AppVideoPlayer videoId={4001} />
                <div>Love Shopping? You'll love this.</div>
              </div>

              <div
                style={{
                  left: "73.2%",
                  top: "10.2%",
                  width: "24.7%",
                  height: "33%"
                }}
              >
                <AppVideoPlayer videoId={5001} />
                <div>Balance your life and your finances</div>
              </div>

              <div
                style={{
                  left: "25.5%",
                  top: "16%",
                  width: "46%",
                  height: "62%"
                }}
              >
                <AppVideoPlayer videoId={3001} />
                <div>Do what you love and get rewarded</div>
              </div>

              <img src="/assets/img/ipad_intro_bg.webp" alt="background" />

              <svg height="100%" width="100%" style={{ zIndex: 10 }}>
                <circle
                  cx="21.23%"
                  cy="72.46%"
                  r="14.2%"
                  fill="transparent"
                  onClick={() => navTo("/family")}
                />
                <circle
                  cx="62.5%"
                  cy="15%"
                  r="14.2%"
                  fill="transparent"
                  onClick={() => navTo("/shopping")}
                />
                <circle
                  cx="85.6%"
                  cy="26.7%"
                  r="14.2%"
                  fill="transparent"
                  onClick={() => navTo("/control")}
                />
                <circle
                  cx="48.5%"
                  cy="47%"
                  r="26.3%"
                  fill="transparent"
                  onClick={() => navTo("/dining")}
                />
                <circle
                  cx="19.53%"
                  cy="26.66%"
                  r="14.2%"
                  fill="transparent"
                  onClick={() => navTo("/travel")}
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Home);
