import { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import Plausible from "plausible-tracker";

import { useAnalyticsDomain, useIsApp } from "../helper/env";
import Dining from "./Dining";
import Family from "./Family";
// import all pages
import Home from "./Home";
import Organization from "./Organization";
import Shopping from "./Shopping";
import Travel from "./Travel";

// import { VideoTest } from "./VideoTest";

export const AppRoutes = (props: any) => {
  return (
    <BrowserRouter>
      {props.children}

      <AnimatedTransitions />
    </BrowserRouter>
  );
};

const codeListRoutes = [
  /* codes scanned from cards (moa)
    PE0LYH7X -> travel
    MHBZTFBD -> family
    EZJIVZ4H -> entertainment  
    D8GHKOHV -> shopping
    UB165WD3 -> control
  */
  {
    path: "/u/PE0LYH7X",
    route: "/travel",
    topic: "Travel",
    origin: "Mall of America"
  },
  {
    path: "/u/MHBZTFBD",
    route: "/family",
    topic: "Kids",
    origin: "Mall of America"
  },
  {
    path: "/u/EZJIVZ4H",
    route: "/dining",
    topic: "Entertainment",
    origin: "Mall of America"
  },
  {
    path: "/u/D8GHKOHV",
    route: "/shopping",
    topic: "Shopping",
    origin: "Mall of America"
  },
  {
    path: "/u/UB165WD3",
    route: "/control",
    topic: "Organization",
    origin: "Mall of America"
  },
  // new store card scans
  /* codes scanned from cards (Location 2)
      HM7EEE2C -> travel
      XONRQZID -> family
      KI3LVYVU -> entertainment
      7SOIX9Q5 -> shopping
      NHRFNZP8 -> control  */
  {
    path: "/u/HM7EEE2C",
    route: "/travel",
    topic: "Travel",
    origin: "Location 2"
  },
  {
    path: "/u/XONRQZID",
    route: "/family",
    topic: "Kids",
    origin: "Location 2"
  },
  {
    path: "/u/KI3LVYVU",
    route: "/dining",
    topic: "Entertainment",
    origin: "Location 2"
  },
  {
    path: "/u/7SOIX9Q5",
    route: "/shopping",
    topic: "Shopping",
    origin: "Location 2"
  },
  {
    path: "/u/NHRFNZP8",
    route: "/control",
    topic: "Organization",
    origin: "Location 2"
  },

  /* codes scanned from app
      RA6VJDZH -> travel
      RVWI318N -> family
      YGX9E5ER -> entertainment
      NWVY6GDY -> shopping
      Z9VVBIDM -> control  */
  { path: "/u/RA6VJDZH", route: "/travel", topic: "Travel", origin: "iOS App" },
  { path: "/u/RVWI318N", route: "/family", topic: "Kids", origin: "iOS App" },
  {
    path: "/u/YGX9E5ER",
    route: "/dining",
    topic: "Entertainment",
    origin: "iOS App"
  },
  {
    path: "/u/NWVY6GDY",
    route: "/shopping",
    topic: "Shopping",
    origin: "iOS App"
  },
  {
    path: "/u/Z9VVBIDM",
    route: "/control",
    topic: "Organization",
    origin: "iOS App"
  }
];

const URL_LOCATION_API =
  "https://capitalonegoals-worker.flopi.workers.dev/api/info/location";

const loadLocationInfo = fetch(URL_LOCATION_API)
  .then((res) => res.json())
  .then(({ country, city, regionCode }) => ({
    location: `${city}, ${regionCode}, ${country}`
  }))
  .catch(() => {});

const AnimatedTransitions = () => {
  let location = useLocation(),
    isApp = useIsApp(),
    analyticsDomain = useAnalyticsDomain();

  // the css transition now needs to handle the scroll reset
  // as this component is the only one to know when exactly the
  // old page has exited steps are as follows
  // - pages fade over (loading needs to be shown as long as the fades)
  // - scroll to the top of the page
  // - loading fading out to reveal content

  // const onExitedCallback = useCallback(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    const { trackEvent } = Plausible({
      trackLocalhost: true,
      domain: analyticsDomain
    });

    const rerouteConfig = codeListRoutes.find(
      ({ path }) => path === location.pathname
    );

    // if a route matches
    if (rerouteConfig) {
      loadLocationInfo.then((locInfo) => {
        // track a card scan
        trackEvent("Code scan", {
          props: {
            origin: rerouteConfig.origin,
            topic: rerouteConfig.topic,
            ...locInfo
          }
        });
      });
    }
  }, [analyticsDomain, location.pathname]);

  return (
    <SwitchTransition mode="in-out">
      <CSSTransition
        key={location.key}
        mountOnEnter
        out={false}
        unmountOnExit
        classNames="fade"
        appear
        timeout={{ appear: 500, enter: 210, exit: 1 }}
        // onExited={!isApp ? onExitedCallback : undefined}
      >
        <Switch location={location} key={location.key}>
          {codeListRoutes.map(({ path, route }) => (
            <Route exact path={path}>
              <Redirect to={route} />
            </Route>
          ))}

          {/* topic pages */}
          <Route exact path="/travel">
            <Travel />
          </Route>
          <Route exact path="/family">
            <Family />
          </Route>
          <Route exact path="/dining">
            <Dining />
          </Route>
          <Route exact path="/shopping">
            <Shopping />
          </Route>
          <Route exact path="/control">
            <Organization />
          </Route>

          <Route exact path="/home">
            {isApp ? <Home /> : <Redirect to="/shopping" />}
          </Route>

          {/* <Route exact path="/video-test">
            <VideoTest />
          </Route> */}

          {/* forward to home depending on platform */}
          <Route path="/*">
            {/* <VideoTest /> */}
            <Redirect to="/home" />
          </Route>
        </Switch>
      </CSSTransition>
    </SwitchTransition>
  );
};
