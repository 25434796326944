import { FC, useEffect, useRef } from "react";
import { useLocation } from "react-router";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useIsApp } from "../../helper/env";

import "./Footer.scss";

interface FooterProps {}

const getFooterProps = (loc: string) => {
  switch (loc) {
    case "/travel":
      return {
        color: "#00a3c2",
        img: "assets/img/travelCard.png",
        altImg: "Travel Card"
      };
    case "/dining":
      return {
        color: "#99c397",
        img: "assets/img/diningCard.png",
        altImg: "Dining Card"
      };
    case "/control":
      return {
        color: "#de5753",
        img: "assets/img/controlCard.png",
        altImg: "Control Card"
      };
    case "/family":
      return {
        color: "#017f9b",
        img: "assets/img/familyCard.png",
        altImg: "Family Card"
      };
    case "/shopping":
      return {
        color: "#f4d24d",
        img: "assets/img/shoppingCard.png",
        altImg: "Shopping Card"
      };
    default:
      return {};
  }
};

export const Footer: FC<FooterProps> = (props: FooterProps) => {
  const location = useLocation(),
    isApp = useIsApp();

  const footerProps = getFooterProps(location.pathname);

  const footerContainer = useRef<HTMLDivElement>(null),
    pincard = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (footerContainer.current && pincard.current) {
      // create the timeline
      var tl = gsap
        .timeline()
        .fromTo(
          pincard.current,
          {
            opacity: 0,
            duration: 0
          },
          {
            opacity: 1,
            duration: 3
          }
        )
        .fromTo(
          footerContainer.current?.querySelectorAll("h4, h3"),
          {
            opacity: 0,
            duration: 0
          },
          {
            opacity: 1,
            duration: 3,
            stagger: 1
          },
          "<2"
        );

      const st = ScrollTrigger.create({
        trigger: footerContainer.current,
        toggleActions: "play pause play pause",
        start: "top 60%",
        end: "90% bottom",
        scrub: true,
        animation: tl
        // markers: true
      });

      return () => {
        st.kill();
        tl.kill();
      };
    }
  }, [footerContainer, pincard]);

  return (
    <>
      {isApp ? (
        <div
          style={{ backgroundColor: footerProps?.color }}
          className="footer_cafe"
          ref={footerContainer}
        >
          <div ref={pincard} className="pincard">
            <img
              className="pincard_img"
              src={footerProps?.img}
              alt={footerProps?.altImg}
            />
          </div>
          <div className="pincard_text">
            <h4>Explore more</h4>
            <h3>
              Interested? Take a card, scan the QR code and experience banking
              reimagined.
            </h3>
          </div>
        </div>
      ) : (
        <div className="footer_web">
          <div className="copyright">&copy;2021 Capital One</div>
          <div>
            <a
              href="https://www.capitalone.com/privacy"
              rel="noreferrer"
              target="_blank"
            >
              Privacy
            </a>
            <a
              href="https://www.capitalone.com/applications/identity-protection/commitment/"
              rel="noreferrer"
              target="_blank"
            >
              Security
            </a>
            <a
              href="https://www.capitalone.com/privacy/online-privacy-policy#collecting-using-sharing-information-accordion-button-5"
              rel="noreferrer"
              target="_blank"
            >
              AdChoices
            </a>
            <a
              href="https://www.capitalone.com/legal/terms-conditions"
              rel="noreferrer"
              target="_blank"
            >
              Terms &amp; Conditions
            </a>
          </div>
          <div className="copyright">
            <span className="gray_on_hover">
              <a href="https://www.fdic.gov/" rel="noreferrer" target="_blank">
                <img
                  src="assets/img/fdic_single.png"
                  alt="FDIC Member"
                  style={{ height: "26px" }}
                />
              </a>
            </span>
            <span>
              <img
                src="assets/img/equal_housing.jpg"
                alt="Equal Housing Opportunity"
                style={{ height: "26px" }}
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
};
