import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import InView from "react-intersection-observer";
import { useSelector } from "react-redux";

import { useIsApp } from "../../helper/env";
import { trigger } from "../../helper/events";
import { RootState } from "../../redux/store";
import { CloudflarePlayer, LocalPlayer } from "./VideoPlayers";

export const videoList = [
  // travel
  {
    videoId: 1001,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Travel_Hero/main.m3u8",
    cloudflareId: "d3d05dccd6d5d40da79bcee33e5a9c8a"
  },
  {
    videoId: 1002,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Travel_Venture/main.m3u8",
    cloudflareId: "41ea3b716cda12f76c82e6c8e409ef53"
  },
  {
    videoId: 1003,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Travel_TSA/main.m3u8",
    cloudflareId: "c0f6e0faea81213951c7d6054487de02"
  },
  {
    videoId: 1004,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Travel_Rewards/main.m3u8",
    cloudflareId: "3400f116a9c90169405e8eb07d98962b"
  },

  // kids
  {
    videoId: 2001,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Kids_Hero/main.m3u8",
    cloudflareId: "7aa33e48caf5695e0ad10b06bc35e92e"
  },
  {
    videoId: 2002,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Kids_Saving/main.m3u8",
    cloudflareId: "f8b6818666369d688eedfb60773dedb2"
  },
  {
    videoId: 2003,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Kids_TeenChecking/main.m3u8",
    cloudflareId: "97d50a50539bec77fc120c7138c07a64"
  },
  {
    videoId: 2004,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Kids_LearnGrow/main.m3u8",
    cloudflareId: "a4142a61f9a0cf9f93bc72d2887897a9"
  },

  // dining
  {
    videoId: 3001,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Dining_Hero/main.m3u8",
    cloudflareId: "c33e87aa2c65d6c132b1019a1675ae92"
  },
  {
    videoId: 3002,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Dining_SavorOne/main.m3u8",
    cloudflareId: "782989c6e7c01dc4088f6abfb626f78f"
  },
  {
    videoId: 3003,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Dining_Experiences/main.m3u8",
    cloudflareId: "df290fd6f530e67e030303c28a0fc22b"
  },
  {
    videoId: 3004,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Dining_GiftCards/main.m3u8",
    cloudflareId: "8947ad95638818782351929b02fd90b8"
  },

  // shopping
  {
    videoId: 4001,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Shopping_Hero/main.m3u8",
    cloudflareId: "005e16295803ba50d7733006591f42e7"
  },
  {
    videoId: 4002,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Shopping_Shopping/main.m3u8",
    cloudflareId: "1673059dc3f49434999fc08f8c054f08"
  },
  {
    videoId: 4003,
    videoLocalSrc:
      "/assets/video/CapitalOne_Cafe_Shopping_Quicksilver/main.m3u8",
    cloudflareId: "c2e2bc1aa2818f5ea333eb50a685564b"
  },
  {
    videoId: 4004,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Shopping_Rewards/main.m3u8",
    cloudflareId: "ac32b743be12adab45b4961e2b53de9f"
  },

  // organization
  {
    videoId: 5001,
    videoLocalSrc: "/assets/video/CapitalOne_Cafe_Organization_Hero/main.m3u8",
    cloudflareId: "210d8eb87ac0707444b71a8ac4e88e8f"
  },
  {
    videoId: 5002,
    videoLocalSrc:
      "/assets/video/CapitalOne_Cafe_Organization_360Checking/main.m3u8",
    cloudflareId: "f476f8f80350a5f2331d3d8bf9880489"
  },
  {
    videoId: 5003,
    videoLocalSrc:
      "/assets/video/CapitalOne_Cafe_Organization_EarlyPay/main.m3u8",
    cloudflareId: "7298ed54623311fa79ef1efc6616858d"
  },
  {
    videoId: 5004,
    videoLocalSrc:
      "/assets/video/CapitalOne_Cafe_Organization_MeetEno/main.m3u8",
    cloudflareId: "d616cd4e5984f0ae3a92d1627517f440"
  }
];

type AppVideoProps = {
  videoId: number;
  className?: string;
  onReady?: () => void;
};

export const AppVideoPlayer: React.FC<AppVideoProps> = memo(
  (props: AppVideoProps) => {
    // check whether offline app or webpage
    const workWithLocalFiles = useIsApp(),
      videoItem =
        videoList.find((listItem) => listItem.videoId === props.videoId) ||
        videoList[0],
      // state vars
      [readyToPlay, setReadyToPlay] = useState(false),
      [inViewState, setInViewState] = useState(false),
      appIsActive = useSelector(
        (appState: RootState) => appState.active.isActive
      );

    // this effect is validating whether the video is ready to play
    // and in case dispatches the video:ready event
    useEffect(() => {
      readyToPlay &&
        trigger("video:ready", { src: props.videoId, isReady: readyToPlay });
    }, [readyToPlay, props.videoId]);

    // construct the video player depending on the app config
    const videoPlayer = useMemo(() => {
      const playerProps = {
        loop: true,
        play: inViewState && appIsActive,
        canPlay() {
          setReadyToPlay(true);
        },
        onError() {
          setReadyToPlay(true);
        }
      };

      if (workWithLocalFiles) {
        return <LocalPlayer src={videoItem.videoLocalSrc} {...playerProps} />;
      } else {
        return (
          <CloudflarePlayer
            cloudflareVideoId={videoItem.cloudflareId}
            {...playerProps}
          />
        );
      }
    }, [
      inViewState,
      appIsActive,
      workWithLocalFiles,
      videoItem.videoLocalSrc,
      videoItem.cloudflareId
    ]);

    const inViewChanged = useCallback(
      (inView, entry) => {
        setInViewState(inView);
      },
      [setInViewState]
    );

    return (
      <div
        className={[
          "videoPlayer",
          readyToPlay ? "readyToPlay" : "",
          props.className
        ].join(" ")}
      >
        <InView
          as="span"
          onChange={inViewChanged}
          style={{ display: "block", height: "100%" }}
        >
          {videoPlayer}
        </InView>
      </div>
    );
  }
);
