import React from "react";

import Page from "../components/Page";

const sections = [
  {
    pinned: true,
    videoId: 3001,
    title: "DO WHAT YOU LOVE AND GET REWARDED",
    text: "Whatever makes life fun - whether it’s spending time with family, going out with friends, or treating yourself to a gift card - Capital One makes memorable experiences even more rewarding."
  },
  {
    videoId: 3002,
    intro: <div className="icon icon-card"></div>,
    title: "SavorOne Card",
    text: "Cash in whether you stay in or go out. Get the SavorOne card and earn 3% cash back on dining, entertainment, popular streaming services and grocery stores.",
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/credit-cards/savorone-dining-rewards/"
      >
        Explore SavorOne
      </a>
    )
  },
  {
    videoId: 3003,
    intro: <div className="icon icon-experiences"></div>,
    title: "Capital One Experiences",
    text: "Turn a meal, sporting event or concert into something unforgettable with inside access to premium experiences as a Capital One cardholder.",
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/credit-cards/benefits/access/"
      >
        Find Experiences
      </a>
    )
  },
  {
    videoId: 3004,
    intro: <div className="icon icon-gift-cards"></div>,
    title: "Gift Cards",
    text: "Redeem your rewards in lots of ways—including for gift cards with your favorite merchants.",
    cta: (
      <a
        className="grv-button"
        href="https://www.capitalone.com/credit-cards/benefits/"
      >
        Learn more
      </a>
    )
  }
];

const Dining: React.FC = () => {
  return <Page sections={sections} />;
};

export default Dining;
