import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { useNavToHome } from "../helper/nav";
import { useTimeout } from "../helper/timeout";
import { LocalPlayer } from "./video/VideoPlayers";

import "./Screensaver.scss";

type ScreenSaverProps = {
  activeAfterSeconds?: number;
};

const ScreenSaver: React.FC<ScreenSaverProps> = (props: ScreenSaverProps) => {
  const [lastInteraction, setLastInteraction] = useState(Date.now),
    [shouldActivate, setShouldActivate] = useState<boolean>(false),
    [activated, setActivated] = useState(false);

  const navigateHome = useNavToHome();

  const interactionHandler = useCallback(
    (evt: Event) => {
      setLastInteraction(Date.now);

      if (activated) {
        setActivated(false);

        evt.preventDefault();
        evt.stopPropagation();

        navigateHome();
      }
    },
    [activated, navigateHome]
  );

  // listen for interactions to trigger activation
  useEffect(() => {
    document.addEventListener("touchstart", interactionHandler, {
      passive: false
    });
    document.addEventListener("mousedown", interactionHandler);
    document.addEventListener("scroll", interactionHandler);

    return () => {
      document.removeEventListener("touchstart", interactionHandler);
      document.removeEventListener("mousedown", interactionHandler);
      document.removeEventListener("scroll", interactionHandler);
    };
  }, [interactionHandler]);

  // display video after inactivity timeout
  const startActivation = useCallback(() => {
    setShouldActivate(true);
  }, []);

  useTimeout(
    startActivation,
    (props.activeAfterSeconds || 60) * 1000,
    lastInteraction
  );

  // in case the screensaver can't be loaded navigate back to home screen
  // that is the second best place to be in that case
  const validateActivation = useCallback(() => {
    if (shouldActivate && !activated) {
      setShouldActivate(false);
      navigateHome();
    }

    // currently the transition to home is causing the loading screen
    // to show ontop of the screensaver (which is a structural issue)
    // // if all activation went well navigate to the home screen
    // // so that the app is ready for the next interaction
    // if (shouldActivate && activated) {
    //   navigateHome();
    // }
  }, [shouldActivate, activated, navigateHome]);

  useTimeout(validateActivation, 2000);

  // cleanup video after fade out
  const completedDeactivation = useCallback(() => {
    setShouldActivate(false);
  }, []);

  const completedActivation = useCallback(() => {
    setActivated(true);
  }, []);

  // useEffect(() => {
  //   console.log("shouldActivate: ", shouldActivate, Date.now());
  //   console.log("activate: ", activated, Date.now());
  // }, [shouldActivate, activated]);

  const shouldTryPlayVideo = useMemo(
    () => shouldActivate || activated,
    [shouldActivate, activated]
  );

  return (
    <CSSTransition
      timeout={3000}
      classNames="fade-slow"
      in={activated}
      onExited={completedDeactivation}
    >
      <div className="screensaver">
        {shouldTryPlayVideo && (
          <LocalPlayer
            loop
            src="/assets/video/ipad_screensaver_1080p/main.m3u8"
            play={shouldTryPlayVideo}
            canPlay={completedActivation}
          />
        )}
      </div>
    </CSSTransition>
  );
};

export default memo(ScreenSaver);
