import {
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef
} from "react";

import type Hls from "hls.js";

import "./VideoPlayers.scss";

const loadHls = () => {
  return import("hls.js").then((mod) => mod.default);
};

type ErrorCallback = (message: string) => void;

export interface IVideoLoader {
  loadVideo(vidRef: HTMLVideoElement): () => void;

  unloadVideo(vidRef: HTMLVideoElement): void;

  onError(errCb: ErrorCallback): void;
}

export class LocalVideoLoader implements IVideoLoader {
  private errCallback: ErrorCallback = () => {};

  constructor(private src: string) {}

  toString() {
    return "LocalVideoLoader: " + this.src;
  }

  onError(errCb: ErrorCallback): void {
    this.errCallback = errCb;
  }

  loadVideo(vidRef: HTMLVideoElement): () => void {
    vidRef.src = this.src;
    vidRef.load();

    return () => this.unloadVideo(vidRef);
  }

  unloadVideo(vidRef: HTMLVideoElement): void {
    vidRef.pause();
    vidRef.src = "";
    vidRef.load();
  }
}

export class HlsVideoLoader implements IVideoLoader {
  private hlsInstance: Hls | undefined;

  private errCallback: ErrorCallback = () => {};

  constructor(private srcUrl: string) {}

  onError(errCb: ErrorCallback): void {
    this.errCallback = errCb;
  }

  toString() {
    return "HlsVideoLoader: " + this.srcUrl;
  }

  loadVideo(vidElem: HTMLVideoElement): () => void {
    if (vidElem.canPlayType("application/vnd.apple.mpegurl")) {
      // If HLS is natively supported, let the browser do the work!
      vidElem.src = this.srcUrl;
      vidElem.load();
    } else {
      loadHls().then((hls: typeof Hls) => {
        if (hls.isSupported()) {
          // If the browser supports MSE, use hls.js to play the video
          this.hlsInstance = new hls({
            // This configuration is required to insure that only the
            // viewer can access the content by sending a session cookie
            // to api.video service
            xhrSetup: function (xhr, url) {
              xhr.withCredentials = false;
            }
          });

          this.hlsInstance.audioTrack = -1;
          this.hlsInstance.loadSource(this.srcUrl);
          this.hlsInstance.attachMedia(vidElem);
          // this.hlsInstance.on(Events.MANIFEST_PARSED, () => {}); //canPlay);

          this.hlsInstance.on(
            // @ts-ignore
            "hlsError",
            (_event: string, data: any) => {
              this.errCallback("HLS Error");
            }
          );
        } else {
          this.errCallback("No playback option available");
        }
      });
    }

    return () => this.unloadVideo(vidElem);
  }

  unloadVideo(vidElem: HTMLVideoElement): void {
    vidElem.pause();

    if (this.hlsInstance) {
      this.hlsInstance.detachMedia();
    }

    vidElem.src = "";
    vidElem.load();
  }
}

type BaseVideoPlayerProps = {
  poster?: string;
  play?: boolean;
  loop?: boolean;

  canPlay?: (evt: any) => void;
  onError?: (evt: any) => void;

  videoRef?: React.RefObject<HTMLVideoElement>;

  loader: IVideoLoader;
};

/**
 * A simple video Player (no controls), just set playing attribute
 * and source thats it. Also supports fallback poster to display.
 * @param props Basic props of a video player
 * @returns
 */
const BaseVideoPlayer: FC<BaseVideoPlayerProps> = memo(
  (props: BaseVideoPlayerProps) => {
    var vidRef = useRef<HTMLVideoElement>(props.videoRef?.current || null),
      { play, loader, canPlay, onError, ...restProps } = props;

    // destroy the video element as otherwise it seems
    // to continue to use resources in the app
    useLayoutEffect(() => {
      if (vidRef.current) {
        return loader.loadVideo(vidRef.current);
      }
    }, [vidRef, loader]);

    // fire can play event
    const canPlayCallback = useCallback(
      (evt: any) => {
        canPlay && canPlay(evt);
      },
      [canPlay]
    );

    useEffect(() => {
      play && loader
        ? vidRef.current?.play().catch(() => {})
        : vidRef.current?.pause();
    }, [loader, play, vidRef]);

    const errorCallback = useCallback(
      (err: any) => {
        onError && onError(err);
      },
      [onError]
    );

    // register for can play callback
    useEffect(() => {
      const vidRefCur = vidRef.current;
      vidRefCur?.addEventListener("canplay", canPlayCallback, {
        once: true
      });
      vidRefCur?.addEventListener("error", errorCallback);

      return () => {
        vidRefCur?.removeEventListener("canplay", canPlayCallback);
        vidRefCur?.removeEventListener("error", errorCallback);
      };
    }, [vidRef, canPlayCallback, errorCallback]);

    useEffect(() => {
      loader.onError(errorCallback);
    }, [loader, errorCallback]);

    return (
      <div>
        <video
          ref={vidRef}
          muted
          playsInline
          disablePictureInPicture
          onError={errorCallback}
          autoPlay={play}
          {...restProps}
        ></video>
      </div>
    );
  }
);

type CloudflarePlayerProps = {
  cloudflareVideoId: string;
} & Omit<BaseVideoPlayerProps, "poster" | "src" | "loader">;

/**
 * Handle the cloudflare specific config for the video player.
 * @param props define the kind of playback for the cloudflare video playback
 * @returns
 */
export const CloudflarePlayer: React.FC<CloudflarePlayerProps> = (
  props: CloudflarePlayerProps
) => {
  const { cloudflareVideoId, ...restProps } = props,
    compData = {
      loader: new HlsVideoLoader(
        `https://videodelivery.net/${cloudflareVideoId}/manifest/video.m3u8`
      ),
      poster: `https://videodelivery.net/${cloudflareVideoId}/thumbnails/thumbnail.jpg?time=1s&width=600`
    };

  return <BaseVideoPlayer {...compData} {...restProps} />;
};

type LocalPlayerProps = {
  src: string;
} & Omit<BaseVideoPlayerProps, "loader">;

export const LocalPlayer: React.FC<LocalPlayerProps> = memo(
  (props: LocalPlayerProps) => {
    const { src, ...restProps } = props,
      compData = {
        loader: new LocalVideoLoader(src)
      };

    return <BaseVideoPlayer {...compData} {...restProps} />;
  }
);
