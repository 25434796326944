import { FC, useLayoutEffect, useMemo, useRef } from "react";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useIsApp } from "../../helper/env";
import { AppVideoPlayer } from "../video/AppVideoPlayer";

import "./Slide.scss";

interface SlideProps {
  videoId: number;

  intro?: string | JSX.Element;

  headline: string;
  text?: string;
  ctaElement?: JSX.Element;
}

const Slide: FC<SlideProps & { pinned?: boolean }> = (
  props: SlideProps & { pinned?: boolean }
) => {
  const { pinned, ...otherProps } = props;
  return pinned ? (
    <PinnedSlide {...otherProps} />
  ) : (
    <ScrollingSlide {...otherProps} />
  );
};

function useSelector(): [
  gsap.utils.SelectorFunc,
  React.RefObject<HTMLDivElement>
] {
  const ref = useRef<HTMLDivElement>(null);
  const sel = useMemo(() => gsap.utils.selector(ref), [ref]);
  return [sel, ref];
}

const PinnedSlide: FC<SlideProps> = (props: SlideProps) => {
  const isApp = useIsApp();

  const [sel, slideRef] = useSelector(),
    pinSpacerClass = ".pin_spacer",
    pinnedSlideClass = ".pinned_slide",
    vidClass = ".background",
    textContainerClass = ".text_overlay",
    headlineClass = ".headline",
    subtitleClass = ".subtitle",
    scrollClass = ".scrollingind";

  // define the scroll animations on this section
  useLayoutEffect(() => {
    // create the timeline
    var tl = gsap
      .timeline()
      .to(
        sel(textContainerClass),
        {
          textShadow: "0px 0px 25px transparent",
          duration: 1
        },
        "<"
      )
      .to(
        sel(headlineClass),
        {
          y: "-15vh",
          duration: 5
        },
        "<"
      )
      .to(
        sel(textContainerClass),
        {
          backgroundColor: "#FFF",
          color: "#141414",
          mixBlendMode: "screen",
          duration: 3
        },
        "<1"
      )
      .to(
        sel(subtitleClass),
        {
          opacity: 1,
          y: "-13vh",
          duration: 3
        },
        "<1"
      )
      .to(
        sel(vidClass),
        {
          opacity: 0,
          duration: 3
        },
        ">-1"
      )
      .to(
        sel(scrollClass),
        {
          borderBottomColor: "#000",
          borderLeftColor: "#000",
          duration: 2
        },
        "<-2"
      )
      .to(
        sel(scrollClass),
        {
          borderBottomColor: "transparent",
          borderLeftColor: "transparent",
          duration: 1
        },
        ">2"
      );

    // define the scroll trigger,
    // do not define inline (with the timeline) this messes up the destruction
    // or explicitly destruct it with tl.scrollTrigger.kill()
    const st = ScrollTrigger.create({
      trigger: sel(pinnedSlideClass),
      toggleActions: "play pause play pause",
      start: "top top",
      end: "bottom top",
      pin: true,
      // invalidateOnRefresh: false,
      pinSpacer: sel(pinSpacerClass),
      scrub: true,
      animation: tl
    });

    return () => {
      st.kill();
      tl.kill();
    };
  }, [sel, slideRef]);

  useLayoutEffect(() => {
    ScrollTrigger.refresh();

    // console.log("Scroll trigger refreshed from pinned");
  });

  return (
    <div className="slide" ref={slideRef}>
      <div className="pin_spacer">
        <div className="pinned_slide">
          <div className="video_placeholder">
            <div className="background" aria-hidden="true">
              <AppVideoPlayer videoId={props.videoId}></AppVideoPlayer>
            </div>

            <div className="text_overlay">
              <div className="text_container" style={{ position: "relative" }}>
                {props.headline && (
                  <h1 className="headline">{props.headline}</h1>
                )}

                {props.text && <div className="subtitle">{props.text}</div>}

                {!isApp && props.ctaElement}
              </div>
            </div>
            <div className="scrollingind"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ScrollingSlide: FC<SlideProps> = (props: SlideProps) => {
  const isApp = useIsApp();

  const slideRef = useRef<HTMLDivElement>(null),
    vidClass = "background",
    introClass = "intro_overlay",
    textClass = "text_outer_container";

  // define the scroll animations on this section
  useLayoutEffect(() => {
    const introReveal = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: slideRef.current,
        toggleActions: "play resume resume reverse",
        start: "10% 80%",
        end: "bottom 80%",
        scrub: true
      }
    });

    const vidElem = slideRef.current?.querySelector("." + vidClass),
      introElem = slideRef.current?.querySelector("." + introClass),
      textElem = slideRef.current?.querySelector("." + textClass);

    if (introElem && vidElem && textElem) {
      introReveal
        .from(introElem.querySelector("div"), {
          scale: 1,
          translateY: "0",
          backgroundColor: "black",
          opacity: 0,
          duration: 0
        })
        .from(vidElem, {
          opacity: 0,
          duration: 0
        })
        .to(introElem, {
          opacity: 1,
          duration: 3
        })
        .to(
          introElem.querySelector("div"),
          {
            scale: 5,
            translateY: "25vh",
            duration: 5
          },
          ">-1"
        )
        .to(
          vidElem,
          {
            opacity: 1,
            duration: 5
          },
          "<0.5"
        )
        .to(
          introElem,
          {
            opacity: 0,
            duration: 2
          },
          ">-3"
        )
        .to(
          textElem.querySelectorAll("h2, .subtitle, a"),
          {
            opacity: 1,
            //translateY: "0vh",
            duration: 3,
            stagger: 0.7
          },
          ">-1"
        );
    }

    return () => {
      introReveal.scrollTrigger?.kill();
      introReveal.kill();
    };
  });

  return (
    <div className="scrolling_slide" ref={slideRef}>
      <div className="video_placeholder" aria-hidden="true">
        <div className="background">
          <AppVideoPlayer videoId={props.videoId} />
        </div>
        {props.intro && (
          <div className="intro_overlay">
            <div>{props.intro}</div>
          </div>
        )}
      </div>

      <div className="text_outer_container">
        <div className="text_container">
          {props.headline && <h2 className="headline">{props.headline}</h2>}
          {props.text && <div className="subtitle">{props.text}</div>}
          {!isApp && props.ctaElement}
        </div>
      </div>
    </div>
  );
};

export default Slide;
